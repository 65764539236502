@import url('https://fonts.googleapis.com/css?family=Open+Sans|Sen&display=swap');

:root {
  --title-font: 'Sen', serif;
  --body-font: 'Open Sans', sans-serif;

  --color-main: yellowgreen;
  --color-main-dark: green;

  --color-accent: rgb(74, 193, 233);
  --color-accent-dark: rgb(45, 159, 197);

  --color-gray: gray;

  font-family: var(--body-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
}

a {
  color: var(--color-accent);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

main {
  max-width: 800px;
  width: 90vw;
  margin: auto;
}

header,
footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: var(--title-font);
  color: var(--color-gray);
}

header {
  > a:hover {
    text-decoration: none;
  }
  .logo {
    font-size: 15pt;
  }
  nav {
    margin: 2px 8px;
    * {
      text-decoration: none;
      color: inherit;
      margin: 0 3px;
    }
  }
}

footer {
  .logo {
    display: inline;
  }
  font-size: 10pt;
}

img {
  width: 300px;
  max-height: 500px;
  float: left;
  margin-right: 20px;
}
